import {
    handleQueryResolve,
} from '../utils';
import { buildMapToField } from "../../kroll/utils";
// PatientID, GenericName = '', Strength = '', extendedWhere = 'rx.RxDate > DATEADD(month, -6, GetDate())'
export default function (GroupID) {
    // FIXME - need to set this up to work with the query for the blister pack 
    if (GroupID) {

        let attrs = [{
            param: 'GroupID',
            type: 'sql.Int',
            value: GroupID // to support like
        }];
        let where = ['NHID = @GroupID'];

        return this.query(`
        SELECT  
ID as PatientID,
LastName,
FirstName,
FORMAT (Birthday, 'dd/MM/yyyy ') as Birthday,
NHID, 
NHInactive
  FROM [pharmacy].[dbo].[Pat]
  where ${where} AND NHInactive = 0
        `, attrs).then(handleQueryResolve);
    }
}